<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>My Order</h3>
      </div>
      <!-- <hr class="hline"> -->
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th class="is-sm text-center">Order ID</th>
            <th class="text-center">Detail</th>
            <th class="is-md text-right">Total Price</th>
            <th class="is-sm text-right">Order Date</th>
            <th class="is-sm text-center">Status</th>
            <th class="is-xs text-right">Option</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-row" v-for="(j, index) in myjobs" :key="index">
            <td data-label="Job Title" class="text-center">{{ j.position }}</td>
            <!-- Job Title -->
            <td data-label="Detail" class="text-center">{{ j.detail }}</td>
            <td data-label="Total Price" class="text-right">2,000,000 LAK</td>
            <td data-label="Pubish Date" class="text-right">01 Dec 2030</td>
            <!-- Pubish Date -->
            <td data-label="status" class="text-center">
              <div class="btn" :class="j.class">{{ j.status }}</div>
            </td>
            <!-- Unpubish Date -->
            <td data-label="Option">
              <div class="icon-group">
                <span @click="modal = true"><i class="fas fa-expand"></i></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="bg" v-if="modal" @click="modal = false"></div>

    <transition name="slide">
      <div class="order-modal" v-if="modal">
        <span @click="modal = false" class="close-modal"
          ><i class="fas fa-arrow-right"></i
        ></span>
        <div class="info-group">
          <h3 class="order"><span>Order No.</span>OR1082211</h3>
          <hr />
          <h3><span>Detail:</span>Small Pack, Medium Pack</h3>
          <h3><span>Total Price:</span> 2,000,000 LAK</h3>
          <h3><span>Order Date:</span> 01 Dec 2060</h3>
          <h3><span>Confirm Payment Date:</span> 01 Dec 2030</h3>
          <h3><span>Ordered by:</span> Mr. Anousone Luangkhot</h3>
        </div>
        <hr />
        <div class="file-group">
          <div class="upload-group">
            <div class="upload-icon">
              <span>Please select file to upload</span>
              <span class="upload-btn">Select File</span>
            </div>
          </div>
          <h3>Uploaded Files</h3>
          <div class="uploaded-file">
            <i class="far fa-file-image icon"></i>
            <span class="file-info">
              <span>Scanned.jpg</span>
              <span>120k</span>
            </span>
            <i class="fas fa-trash btn"></i>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  data: () => ({
    tips: false,
    myjobs: [
      {
        position: "OR1082211",
        status: "Pending",
        class: "confirm-btn",
        detail: "Small Pack, Medium Pack",
      },
      {
        position: "OR1082224",
        status: "Checking",
        class: "checking-btn",
        detail: "Small Pack, Medium Pack",
      },
      {
        position: "OR1082278",
        status: "Paid",
        class: "paid-btn",
        detail: "2 Post, 10 Week",
      },
      {
        position: "OR1082432",
        status: "Paid",
        class: "paid-btn",
        detail: "10 Post, 2 Week",
      },
    ],
    modal: false,
  }),
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block;
  transition: all ease-in-out 0.2s;
  border: none;
  box-shadow: none;
  box-shadow: unset;
  font-size: 14px;
  padding: 5px 10px;
  color: var(--text-color);
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    color: var(--text-color);
  }
  &:focus {
    color: var(--text-color);
  }
  &.confirm-btn {
    background-color: var(--alert-color);
  }
  &.checking-btn {
    background-color: var(--info-color);
    pointer-events: none;
  }
  &.paid-btn {
    pointer-events: none;
    background-color: var(--grey-color);
    color: var(--text-color);
  }
}

// modal start
.bg {
  cursor: pointer;
  background-color: rgba(#333, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.order-modal {
  padding: 60px 40px;
  background-color: #fff;
  box-shadow: -5px 0px 20px -5px rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 40%;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  animation: modal 0.1s;
  @include mobile {
    width: 100%;
    padding: 30px 20px;
  }
  .close-modal {
    cursor: pointer;
    margin-top: -40px;
    margin-bottom: 15px;
    display: block;
    font-size: 18px;
    @include mobile {
      margin-top: -10px;
    }
  }
  .info-group {
    hr {
      margin: 15px 0;
    }
    h3 {
      font-size: 14px;
      padding: 0;
      margin: 0;
      line-height: 1.5;
      span {
        color: var(--primary-color);
        font-weight: 700;
      }
      &.order {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.1;
        span {
          color: var(--text-color);
          display: block;
          font-weight: normal;
          margin-right: 5px;
          font-size: 14px;
          margin: 0;
          line-height: 1.1;
        }
      }
    }
  }
  .upload-group {
    margin-bottom: 20px;
    .upload-icon {
      padding: 10px 15px;
      box-shadow: 0px 0px 0px 4px var(--primary-color);
      border-radius: 5px;
      display: flex;
      align-items: center;
      .upload-btn {
        cursor: pointer;
        margin-left: auto;
        padding: 5px 15px;
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }
  .file-group {
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .uploaded-file {
      cursor: pointer;
      padding: 10px 15px;
      box-shadow: 0px 0px 0px 1px #e5e5e5;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      &:hover > .btn {
        color: var(--alert-color);
      }
      .icon {
        color: var(--primary-color);
        font-size: 30px;
      }
      .file-info {
        margin-left: 10px;
        span {
          margin: 0;
          padding: 0;
          line-height: 1.2;
          display: block;
          font-size: 14px;
          color: var(--text-color);
          &:first-child {
            color: var(--text-color);
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .btn {
        margin-left: auto;
      }
    }
  }
}
// modal end

.slide-enter-active {
  animation: modal 0.2s;
}

.slide-leave-active {
  animation: nomodal 0.2s;
}

@keyframes modal {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes nomodal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
